














import { ChartParams } from "@/views/chat/statistics/logic/types";
import { BaseReferralUser, ReferralsRawData } from '@/views/chat/statistics/logic/usersStatTypes'

import { ApexChartLocales } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import { UseFields } from "piramis-base-components/src/components/Pi";

import { Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { sum } from "lodash";

@Component({
  components: {
    VueApexCharts
  }
})
export default class UsersLocale extends UseFields {
  @Prop() rawData!: ReferralsRawData

  locales: ChartParams | null = null

  mounted(): void {
    const langKeys: Record<string, number> = {}
    const LANG_NOT_SET_KEY = 'UNDEFINED'
    let total = 0

    const setLang = (user: BaseReferralUser) => {
      if (user.user_lang) {
        langKeys[user.user_lang] = (langKeys[user.user_lang] ?? 0) + 1
      } else {
        langKeys[LANG_NOT_SET_KEY] = (langKeys[LANG_NOT_SET_KEY] ?? 0) + 1
      }

      total += 1
    }

    this.rawData.users.forEach(user => {
      setLang(user)

      user.referral_users.forEach(ref => {
        setLang(ref)
      })
    })

    const series = Object
      .entries(langKeys)
      .reduce((acc: Array<any>, [ key, value ]) => {
        acc.push({
          data: Math.round(value / (total / 100)),
          name: key === LANG_NOT_SET_KEY ? this.$t('statistics_users_lang_not_set').toString() : key
        })
        return acc
      }, [])
      .sort((a, b) => b.data - a.data)

    if (series.length > 4) {
      series.splice(4, series.length, {
        data: sum(series.slice(4).map(s => s.data)),
        name: this.$t('statistics_users_relation_other_languages').toString()
      })
    }

    this.locales = {
      series: [ { data: series.map(s => s.data) } ],
      options: this.getOptions(series.map(s => s.name))
    }
  }

  getOptions(labels: Array<string>): any {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        position: 'bottom',
        formatter: (label: string, obj: any) => {
          const value = obj.w.config.series[0].data[obj.seriesIndex]
          return `${ label }: ${ value }%`
        }
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  download: false
                }
              },
              height: 300
            }
          }
        }
      ],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          formatter: (d: string) => {
            return `${ d }%`
          }
        }
      },
      grid: {
        show: true
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (val: string,obj: any) {
              const label = labels[obj.dataPointIndex]
              const value = obj.series[0][obj.dataPointIndex]
              return `${ label }: ${ value }%`;
            },
          },
          formatter: function () {
            return '';
          },
        },
      },
    }
  }
}
