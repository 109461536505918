















































































import i18n from "@/i18n/i18n";
import TableUserActionButtons from '@/components/TableUserActionButtons.vue'
import { Referral, ReferralUser } from "../../logic/usersStatTypes";
import PiTableHeader from "@/components/chat/PiTableHeader.vue";

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";

@Component({
  components: {
    PiTableHeader,
    UserMainInfo,
    TableUserActionButtons,
    TableExportButtons,
  },
  data() {
    return {
      i18n
    }
  },
  name: 'referrals-table'
})
export default class ReferralsTable extends Vue {
  @Prop() main!: boolean
  @Prop() users!: Array<Referral>

  search = ''

  tableData: Array<Referral> = []

  expandedRowKeys = []

  columns = [
    {
      title: i18n.t('users_statistics_table_user_name').toString(),
      key: 'user_name',
      scopedSlots: { customRender: 'userInfo' },
    },
    {
      title: i18n.t('users_statistics_table_referrals').toString(),
      key: 'referrals',
      dataIndex: 'referrals',
      scopedSlots: { customRender: 'referrals' },
    },
    {
      title: i18n.t('users_statistics_table_user_lang').toString(),
      key: 'user_lang',
      dataIndex: 'user_lang',
      scopedSlots: { customRender: 'user_lang' },
    }
  ]
  tableTemplate = 0
  @Watch('search')
  searchWatcher(): void {
    this.tableData = this.searchResult
    this.tableTemplate += 1
  }

  get searchResult(): Array<Referral> {
    if (!this.search.length) {
      return this.users
    } else {
      const search: Array<Referral> = []
      this.users.forEach(user => {
        if (user.user_name && user.user_name.toLowerCase().includes(this.search.trim().toLowerCase())) {
          search.push(user)
        }
      })
      return search
    }
  }

  getTable(): any {
    return this.users.map(user => {
      return {
        user_id: user.user_id,
        avatar: user.avatar,
        user_name: user.user_name,
        user_login: user.user_login ? user.user_login : '-',
        user_lang: user.user_lang ? user.user_lang : '-',
        link: user.pm_link,
        referrals: user.referrals
      }
    })
  }

  getUsers(current_user: ReferralUser | Referral): any {
    let refUsers: Array<Referral> = []
    let noneRefUsers: Array<ReferralUser> = []
    const referral = this.users.find(user => user.user_id === current_user.user_id)

    if (referral && referral.referral_users) {
      referral.referral_users.forEach(user => {
        const findUser = this.users.find(item => user.user_id === item.user_id)
        if (findUser) {
          refUsers.push(findUser)
        } else {
          noneRefUsers.push(user as Referral)
        }
      })
    }

    let returnArr: Array<ReferralUser | Referral> = refUsers.sort((a, b) => b.referrals - a.referrals).slice()
    returnArr.push(...noneRefUsers)
    return returnArr
  }

  created(): void {
    this.tableData = this.searchResult
  }
}
