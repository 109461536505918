import { render, staticRenderFns } from "./referralsLocales.vue?vue&type=template&id=2cb51992&scoped=true&"
import script from "./referralsLocales.vue?vue&type=script&lang=ts&"
export * from "./referralsLocales.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb51992",
  null
  
)

export default component.exports